<template>
  <div class="pxp__workspace">
    <Header :workspace-id="workspaceId" />
    <!-- <OnlineCollaborators
        v-show="route.name !== 'Billing' && route.name !== 'Course' && route.name.indexOf('Account') === -1"
        class="pxp__online-collaborations"/> -->
    <router-view />
  </div>
</template>

<script>
import Header from "@/components/Global/Header";
import { ref, reactive, computed, watch, provide, inject, onUnmounted } from  'vue';
import {joinToWorkspace, leaveFromWorkspace, myWorkspaceRequestApi, createWorkspaceRequestApi} from "@/api/workspace";
import OnlineCollaborators from "@/components/Global/OnlineCollaborators";
import {useRoute} from "vue-router";
import {researchQuestionInitialization} from "@/setups/initResearchQuestionSetup"
import {todoTodayInitialization} from "@/setups/initTodoTodaySetup"

export default {
  name: "Workspace",
  components: { OnlineCollaborators, Header },
  props: {
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    // workspace
    const socket = inject('socket')
    const activeWorkspaceId = inject('activeWorkspaceId')
    const route = useRoute()

    watch(() => props.workspaceId, (newValue, oldValue) => {
      activeWorkspaceId.value = newValue
      leaveFromWorkspace(oldValue, socket.value.id)
      joinToWorkspace(newValue, socket.value.id)
    })

    activeWorkspaceId.value = props.workspaceId

    joinToWorkspace(props.workspaceId, socket.value.id)
    onUnmounted(() => {
      leaveFromWorkspace(props.workspaceId, socket.value.id)
    })

    // apps
    researchQuestionInitialization(props)
    todoTodayInitialization(props)

    return {
      route
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/utility.scss";

.pxp__workspace {
  @include suppress-scroll;
  min-height: 100vh;
  width: 100vw;
}

.pxp__online-collaborations {
  position: absolute;
  top: 75px;
  right: 36px;
}
</style>
