<template>
  <div class="online-collaborators">
    <template v-for="(user, index) in usersOnline" :key="'key-' + index">
      <Avatar :id="'user-online-' + user.id" class="avatar" :src="avatarThumbnail(user)" :size="26" :full-name="user.firstName + ' ' + user.lastName"/>
    </template>
  </div>
</template>

<script>
import Avatar from "@/components/UI-Kit/Avatar"
import {inject, ref} from "vue"



export default {
  name: "OnlineCollaborators",
  components: {
    Avatar
  },
  setup () {
    const socket = inject('socket')
    const usersOnline = ref([])
    socket.value.on('updated_workspace_online', (data) => {
      usersOnline.value = data.online
    })
    return {
      usersOnline
    }
  },
  methods: {
    avatarThumbnail: (user) => {
      if (
          user.avatar &&
          user.avatar.formats &&
          user.avatar.formats.thumbnail &&
          user.avatar.formats.thumbnail.url
      ) {
        return user.avatar.formats.thumbnail.url
      }
    }
  }
}
</script>

<style scoped lang="scss">
.online-collaborators {
  display: flex;
  flex-direction: row;
}

.avatar {
  margin-left: -10px;
}
</style>