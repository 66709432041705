import { computed, ref, watch, reactive, provide } from "vue"
import {getResearchQuestion, getTask} from "@/api/sprint";

export const researchQuestionInitialization = function (props) {
    // main data
    const tasks = reactive({})

    const rqs = reactive({})
    const date = ref(() => (new Date()).toISOString())

    // Filter task by status
    const sortedData = computed(() => getSortedData(rqs, tasks, date.value()))
    const findPlaceByDate = function (entity, date) {
        const move = entity.move
        const searchDate = Date.parse(date)


        let startIndex = 0;
        let endIndex = move.length - 1;

        while(startIndex !== endIndex) {
            let middleIndex = Math.ceil((startIndex + endIndex) / 2);

            if(searchDate >= Date.parse(move[middleIndex].date))
                startIndex = middleIndex;
            else
                endIndex = middleIndex - 1;
        }


        if (Date.parse(move[startIndex].date) <= searchDate && endIndex !== -1)
            return move[startIndex]
        else
            return undefined

    }
    const getSortedData = function(rqs, tasks, date) {
        const sorted = reactive({
            rq: reactive([]),
            todo: reactive([]),
            doing: reactive([]),
            done: reactive([]),
            deleted: reactive([])
        })

        // find first item in all list

        for (let rqKey in rqs) {

            let rq = rqs[rqKey]

            if (!rq.hasOwnProperty('type'))
                rq.type = "rq"

            if (!rq.hasOwnProperty('tasks'))
                rq.tasks = []

            if (rq.hasOwnProperty('rqList'))
                rq.rqList.splice(0, rq.rqList.length)
            else
                rq.rqList = []

            let rqPlace = findPlaceByDate(rq, date)

            if (typeof rqPlace !== 'undefined') {

                if (rqPlace.neighborTop === 'column-top')
                    sorted[rqPlace.column].push(rq)

                for (let rqTask of rq.tasks) {

                    let task = tasks[rqTask.id]
                    if (!task.hasOwnProperty('type'))
                        task.type = 'tsk'

                    let taskPlace = findPlaceByDate(task, date)

                    if (typeof taskPlace !== 'undefined') {
                        if (taskPlace.neighborTop === 'column-top')
                            sorted[taskPlace.column].push(task)
                        else if (taskPlace.neighborTop === 'rq-top')
                            rq.rqList.push(task)
                    }
                }
            }
        }

        // set all item in order

        for (let column in sorted) {
            if (sorted[column].length > 0) {
                let flag = true

                while (flag) {
                    let now = sorted[column][sorted[column].length - 1]

                    if (now.type === 'rq') {
                        if (now.rqList.length > 0) {
                            let rqFlag = true

                            while (rqFlag) {
                                let nowTask = now.rqList[now.rqList.length - 1]
                                let nextTask = findPlaceByDate(nowTask, date).neighborBottom

                                if (typeof nowTask.researchQuestion === 'object') {
                                    nowTask.researchQuestion = nowTask.researchQuestion.id
                                }

                                if (nextTask === 'rq-bottom') {
                                    rqFlag = false
                                    break
                                } else {
                                    const taskId = nextTask.split('-')[1]
                                    const task = tasks[taskId]

                                    now.rqList.push(task)
                                }
                            }
                        }
                    }
                    else {
                        let nowRqId

                        if (typeof now.researchQuestion === 'object')
                            nowRqId = now.researchQuestion.id
                        else
                            nowRqId = now.researchQuestion

                        if (sorted[column].length - 1 === 0 || sorted[column][sorted[column].length - 2].type !== 'tsk') {
                            now.researchQuestion = {
                                id: nowRqId,
                                name: rqs[nowRqId].name
                            }
                        } else {
                            const lastItem = sorted[column][sorted[column].length - 2]

                            let lastRqId
                            if (typeof lastItem.researchQuestion === 'object')
                                lastRqId = lastItem.researchQuestion.id
                            else
                                lastRqId = lastItem.researchQuestion

                            if (lastRqId === nowRqId) {
                                now.researchQuestion = nowRqId
                            } else {
                                now.researchQuestion = {
                                    id: nowRqId,
                                    name: rqs[nowRqId].name
                                }
                            }
                        }
                    }

                    let next = findPlaceByDate(now, date).neighborBottom

                    if (next === 'column-bottom') {
                        flag = false
                        break
                    } else if (next.split('-')[0] === 'rq') {

                        const rqId = next.split('-')[1]
                        const rq = rqs[rqId]

                        sorted[column].push(rq)

                    } else {

                        const taskId = next.split('-')[1]
                        const task = tasks[taskId]

                        sorted[column].push(task)

                    }
                }
            }
        }

        return sorted
    }

    const loadStatus = ref('loading')
    const init = async function () {
        loadStatus.value = 'loading'

        const rqsData = await getResearchQuestion(props.workspaceId)
        const tasksData = await getTask(props.workspaceId)

        for (let id in rqs)
            delete rqs[id];

        for (let id in tasks)
            delete tasks[id];


        rqsData.forEach(item => {
            rqs[item.id] = reactive(item)
        })

        tasksData.forEach(item => {
            tasks[item.id] = reactive(item)
        })

        loadStatus.value = 'loaded'
    }

    init()
    watch (() => props.workspaceId, () => {
        init()
    })

    provide('rqs', rqs)
    provide('tasks', tasks)
    provide('sortedRQData', sortedData)
    provide('loadRQStatus', loadStatus)
    provide('findPlaceByDate', findPlaceByDate)
    provide('date', date)
}