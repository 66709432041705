import {reactive, ref, watch, provide, nextTick, computed} from "vue"
import {createTodoTask, deleteTodoTask, getActualTodoTask,
    updateTodoTask, postponeTodoTask, completeTodoTask} from "@/api/todotoday"
import {v1 as uuid} from "uuid";

export const todoTodayInitialization = function (props) {
    const loadTTTaskData = ref(false)
    const actualTTTasks = reactive([])

    const init = async function () {
        loadTTTaskData.value = true
        actualTTTasks.splice(0, actualTTTasks.length)
        let data

        data = await getActualTodoTask(props.workspaceId)

        data.forEach(item => {
            actualTTTasks.push(item)
        })

        loadTTTaskData.value = false
    }

    const createTTTaskFromSTask = function(fromTask) {
        const newTTTask = reactive({
            pomodoro: 3,
            plan: "",
            task: fromTask.id
        })

        createTodoTask(props.workspaceId, {...newTTTask})
            .then((data) => {
                newTTTask.id = data.id
                newTTTask.timeStart = data.timeStart
                newTTTask.timeFinish = data.timeFinish
                newTTTask.timePostpone = data.timePostpone
                newTTTask.workspace = data.workspace
                newTTTask.sync = false
            })

        newTTTask.id = uuid().split('-').join('')
        newTTTask.sync = true

        actualTTTasks.push(newTTTask)
    }

    const deleteTTTaskByIndex = function(index) {
        const todoTask = actualTTTasks[index]

        deleteTodoTask(todoTask.id, props.workspaceId)

        actualTTTasks.splice(index, 1)
    }

    const deleteTTTaskById = function(id) {
        deleteTodoTask(id, props.workspaceId).catch(() => {})
    }

    const completeTTTaskByIndex = function(index) {
        const todoTask = actualTTTasks[index]

        todoTask.sync = true
        completeTodoTask(todoTask.id, props.workspaceId)
            .then((data) => {
                todoTask.timeFinish = data.timeFinish
                todoTask.sync = false
            })

        todoTask.timeFinish = (new Date()).toISOString()
    }

    const postponeTTTaskByIndex = function(index) {
        const todoTask = actualTTTasks[index]

        todoTask.sync = true
        postponeTodoTask(todoTask.id, todoTask.workspace)
            .then((date) => {
                todoTask.timePostpone = date.timePostpone
                todoTask.sync = false
            })

        const newDay = new Date()
        newDay.setHours(23, 59,59,999)

        todoTask.timePostpone = newDay.toISOString()
    }

    const checkPostponeByIndex = function(index) {
        const todoTask = actualTTTasks[index]

        const postponeTime = new Date(todoTask.timePostpone)

        return postponeTime.getTime() > Date.now()
    }

    const countPostpone = computed(() => {
        return actualTTTasks.reduce((count, item, index) => {
            if (checkPostponeByIndex(index))
                return count + 1
            return count
        }, 0)
    })

    const updateTTTaskByIndex = function(index) {
        const todoTask = actualTTTasks[index]
        if (todoTask.hasOwnProperty('sync') && todoTask.sync) {
            nextTick(() => {
                updateTTTaskByIndex()
            })
        } else {
            todoTask.sync = true
            updateTodoTask(todoTask.id, todoTask.workspace, {...todoTask})
                .then(() => {
                    todoTask.sync = false
                })
        }
    }

    init()
    watch (() => props.workspaceId, () => {
        init()
    })

    provide('loadTTTaskData', loadTTTaskData)
    provide('actualTTTasks', actualTTTasks)
    provide('createTTTaskFromSTask', createTTTaskFromSTask)
    provide('deleteTTTaskByIndex', deleteTTTaskByIndex)
    provide('deleteTTTaskById', deleteTTTaskById)
    provide('updateTTTaskByIndex', updateTTTaskByIndex)
    provide('completeTTTaskByIndex', completeTTTaskByIndex)
    provide('postponeTTTaskByIndex', postponeTTTaskByIndex)
    provide('checkPostponeByIndex', checkPostponeByIndex)
    provide('countPostpone', countPostpone)
}